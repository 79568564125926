.speciality-container-red {
	div.multiselect {
		border: 2px red solid;
		border-radius: 5px;
	}
	.no-speciality-text {
		color: red;
		font-weight: 600;
	}
	div.multiselect div.multiselect__select {
		display: none;
	}
}
